<template>
  <div id="vueConfirm">
    <!--v-show必须写在transition的第一个子元素上，并且需要key值-->
    <!--使用方法：this.$confirm({text:'aaa',duration:2000,callback:function(){alert('我是回调函数')}})-->
    <transition-group name="fade" tag="div">
      <div class="confirm" key="1" v-show="data.show"></div>
    </transition-group>
    <transition-group name="fade" tag="div">
      <div class="confirm-dialog" key="2" v-show="data.show">
        <div class="flex-1 c-container">
          <p class="c-title" v-if="data.title" v-html="data.title"></p>
          <div class="c-con" v-if="data.text" v-html="data.text"></div>
          <div v-if="data.html" v-html="data.html"></div>
        </div>
        <div class="btn-confirm flex">
          <span class="flex-1 btn-cancel" @click.stop="cancelFunc()">{{ data.cancelText }}</span>
          <span class="flex-1 btn-submit" @click.stop="sureFunc()">{{ data.sureText }}</span>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script setup>
import eventBus from '../event-bus';
import { onMounted, reactive, watch } from 'vue';

let timer;

const data = reactive({
  show: false,
  title: '',
  text: '',
  html: '',
  titleColor: '#333',
  textColor: '#999',
  sureText: '确定',
  cancelText: '取消',
  sureColor: '#F9940E',
  cancelColor: '#999',
  callback: null,
  cancel: null,
});

watch(
  () => data.show,
  () => {
    timer && clearTimeout(timer);
    if (!data.show) {
      clearData();
    }
  },
  {}
);

const showItem = options => {
  const setData = key => {
    if (typeof options[key] !== 'undefined') {
      data[key] = options[key];
    }
  };
  const keys = [
    'show',
    'title',
    'text',
    'html',
    'titleColor',
    'textColor',
    'sureText',
    'cancelText',
    'sureColor',
    'cancelColor',
    'cancel',
    'callback',
  ];
  keys.forEach(v => setData(v));
  if (typeof options.show !== 'boolean') {
    data.show = true;
  }
};

const sureFunc = () => {
  data.callback && data.callback();
  data.show = false;
};

const cancelFunc = () => {
  data.show = false;
  data.cancel && data.cancel();
};

const clearData = () => {
  //清除上一次进入时所保留的值
  timer = setTimeout(() => {
    data.title = '';
    data.text = '';
    data.html = '';

    data.cancelText = '取消';
    data.sureText = '确定';

    data.titleColor = '#F9940E';
    data.textColor = '#999';

    data.sureColor = '#F9940E';
    data.cancelColor = '#999';

    data.callback = null;
    data.cancel = null;
  }, 500);
};

onMounted(() => {
  eventBus.$on('dialog-confirm', showItem);
});
</script>

<style scoped lang="scss">
@import '@/assets/css/_themeify.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}
.fade-enter-active,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1008;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}
.confirm-dialog {
  position: fixed;
  display: table;
  z-index: 5000;
  width: calc(100% - 80px);
  max-width: 300px;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: 0;
  margin: auto;
  text-align: center;
  background: #ffffff;
  @include themeify {
    background: themed(ui_card_primary_background);
  }
  border-radius: 16px;
}
.c-container {
}
.c-container .c-title {
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  font-size: 18px;
  padding: 24px 8px 16px;
  line-height: 27px;
  word-break: break-all;
  white-space: normal;
}
.c-container .c-con {
  color: rgba(0, 0, 0, 1);
  margin: 0 15px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22.5px;
  text-align: center;
}
.btn-confirm {
  text-align: center;
  width: 100%;
  justify-content: space-around;
  border-top: 1px solid #eee;
  border-top: none;
  padding: 32px 24px 24px;
}

.btn-confirm span {
  font-size: 16px;
  display: block;
  margin: 0 8px;
  line-height: 38px;
  height: 38px;
  border-radius: 20px;
  font-weight: 500;
}
// 不背单词
.btn-confirm {
  .btn-cancel {
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  .btn-submit {
    color: rgba(255, 255, 255, 1);
    background: var(--IC-brand-primary-c, rgba(255, 84, 92, 1));
  }
}
</style>
