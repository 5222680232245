import './assets/css/base.scss';
import './assets/css/_var.css';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { createHead } from '@unhead/vue';
import { Dialog } from './plugins/iscool-dialog';
import axios from 'axios';
import VueAxios from 'vue-axios';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBvMOwEG5SCyIbhcIoUs62-vdDeNtIY15w',
  authDomain: 'goikoi.firebaseapp.com',
  projectId: 'goikoi',
  storageBucket: 'goikoi.firebasestorage.app',
  messagingSenderId: '537741688281',
  appId: '1:537741688281:web:261b3c30bca3879814adc8',
  measurementId: 'G-DYY0V6V8D3',
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
getAnalytics(firebaseApp);

import App from './App.vue';
import router from './router';

const app = createApp(App);
app.use(VueAxios, axios);
app.provide('axios', app.config.globalProperties.axios);
app.use(createHead());
app.use(createPinia());
app.use(router);
app.use(Dialog);

app.mount('#app');
