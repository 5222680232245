<template>
  <div>
    <transition name="slide">
      <div class="wrap" v-show="data.show">
        <div class="loading flex" :class="{ top: !!data.top }" :style="{ paddingTop: data.top }">
          <i v-html="data.text" :style="{ top: data.top }"></i>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import eventBus from '../event-bus';
import { onMounted, reactive, watch } from 'vue';

let clr;

const data = reactive({
  top: '',
  show: false,
  text: '',
  duration: 1500,
  callback: null,
});

watch(
  () => data.show,
  () => {
    if (!data.show) {
      data.duration = 1500;
      data.callback = null;
    } else {
      autoClose();
    }
  },
  {}
);

const showItem = options => {
  const setData = key => {
    if (typeof options[key] !== 'undefined') {
      data[key] = options[key];
    }
  };
  const obj = {
    top: '',
    show: false,
    text: '',
    duration: 1500,
    callback: null,
  };
  const keys = Object.keys(obj);
  keys.forEach(v => setData(v));
  if (typeof options.show !== 'boolean') {
    data.show = true;
  }
};

const autoClose = () => {
  if (clr) {
    //如果手速过快，即把上一次的timer清除
    clearTimeout(clr);
  }
  clr = setTimeout(() => {
    data.show = false;
    data.callback && data.callback();
  }, data.duration);
};

onMounted(() => {
  eventBus.$on('dialog-ic-toast', showItem);
});
</script>

<style scoped lang="scss">
.wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000;
  width: 100%;
  height: 100%;
  margin: auto;
  display: flex;
}
.loading {
  width: 100%;
  justify-content: center;
  &.top {
    align-items: flex-start;
    i {
      position: relative;
    }
  }
}
.loading i {
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-style: normal;
  font-weight: normal;
  max-width: 70%;
  min-width: 80px;
  padding: 16px;
  text-align: center;
  // margin-top: -50px;
  border-radius: 16px;
}

.lexis-wrap .loading {
  i {
    border-radius: 8px;
    padding: 6px 16px;
  }
}

.slide-enter-active,
.slide-leave-active {
  -webkit-transition: all 0.12s ease;
  transition: all 0.12s ease;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
}
</style>
