export default [
  // {
  //   path: '/gk/vip/purchase',
  //   name: 'VipPurchase',
  //   component: () => import('@/views/payment/vip-purchase/VipPurchase.vue'),
  // },
  {
    path: '/gk/vip/purchase/new',
    name: 'NewVipPurchase',
    component: () => import('@/views/payment/vip-purchase/VipPurchaseV2.vue'),
    alias: ['/gk/vip/purchase'],
  },
];
